import React, {useState} from 'react';
import Button from '@components/atoms/Button';
import Container from '@components/atoms/Container';
import TrackingLink from '@components/atoms/TrackingLink';
import {defaultLanguageInfo} from '@helpers/getLanguageInfo';
import {useLanguageContext} from '@helpers/LanguageContext';
import headerSettings from '@netlify/site-settings/header.yml';
import {useStaticQuery, graphql} from 'gatsby';
import Helmet from 'react-helmet';
/* eslint-disable */
import Logo from '-!svg-react-loader!@images/hudl-logo.svg';
import Chevron from '-!svg-react-loader!@images/hudl-chevron.svg';
import UsSiteModal from '@components/organisms/UsSiteModal';
import LanguagePicker from '@components/organisms/LanguagePicker';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
/* eslint-enable */
import './style.navigation.scss';

const Navigation = () => {
    const {pageLanguageCode, pageLanguageName} = useLanguageContext();

    const defaultPath = defaultLanguageInfo.languages[pageLanguageCode]?.path || '';

    // Pulls out the navigation data from netlify.
    const navData = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(navbar)/"}}) {
                    nodes {
                        frontmatter {
                            title
                            language
                            menuItems {
                                label
                                linkType
                                linkUrl
                                subMenuItems {
                                    label
                                    linkUrl
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    // Only return the menu that matches the current language.
    const headerMainNavigationData = navData.allMarkdownRemark.nodes.find(navNodes => (navNodes.frontmatter.language === pageLanguageCode));

    const mainNavigation = headerMainNavigationData.frontmatter.menuItems;

    const [hudlHamburgerMenuOpen, sethudlHamburgerMenu] = useState(false);
    const togglehudlHamburgerMenu = () => {
        sethudlHamburgerMenu(!hudlHamburgerMenuOpen);
    };

    const hudlHamburgerMenuKeyDown = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            togglehudlHamburgerMenu();
        }
    };

    const mobileNav = mainNavigation.map((menuItem, index) => {

        const hasSubNav = menuItem.subMenuItems !== null;

        let subMenuHeight = 0;

        if (hasSubNav) {
            // Calculate height of hidden subnav for animation requirements
            // 56 = <li> height
            // 32 = <ul> vertical padding
            subMenuHeight = `${56 * menuItem.subMenuItems.length + 32}px`;
        }

        if (menuItem.linkType === 'link') {
            return (
                <AccordionItem key={`${menuItem.linkUrl}-${index.toString()}`} className="hudl-nav__list-item--mobile">
                    <AccordionItemState>
                        {({expanded}) => (
                            <>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {hasSubNav === false && (
                                            <TrackingLink component="top_nav" to={menuItem.linkUrl} className="hudl-nav__link--mobile"><span>{menuItem.label}</span></TrackingLink>
                                        )}
                                        {hasSubNav === true && (
                                            <p className={`hudl-nav__link--mobile ${expanded === true ? ' hudl-nav__link--expanded' : ''}`}>
                                                {menuItem.label}
                                                <Chevron fill="#fff" />
                                            </p>
                                        )}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {hasSubNav === true && (
                                    <AccordionItemPanel style={{height: expanded === true ? subMenuHeight : 0}}>
                                        <ul className="hudl-sub-nav--mobile">
                                            {menuItem.subMenuItems.map(subItem => (
                                                <li key={subItem.label.toLowerCase().replace(/\s/g, '')} className="hudl-sub-nav__item--mobile">
                                                    <TrackingLink component="sub_nav" to={subItem.linkUrl} className="hudl-sub-nav__link--mobile">{subItem.label}</TrackingLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionItemPanel>
                                )}
                            </>
                        )}
                    </AccordionItemState>
                </AccordionItem>
            );
        }
        if (menuItem.linkType === 'button') {
            return <li key={`${menuItem.linkUrl}-${index.toString()}`} className="hudl-margin-x--xl hudl-margin-top--xl"><Button component="top_nav" variant="primary-button-light" to={menuItem.linkUrl}>{menuItem.label}</Button></li>;
        }
        return null;
    });

    const mainNav = mainNavigation.map((menuItem, index) => {

        const hasSubNav = menuItem.subMenuItems !== null;

        if (menuItem.linkType === 'link') {
            return (
                <li key={`${menuItem.linkUrl}-${index.toString()}`} className="hudl-nav__list-item--desktop">
                    {hasSubNav === false && (
                        <TrackingLink component="top_nav" to={menuItem.linkUrl} className="hudl-nav__link--desktop">
                            <span>
                                {menuItem.label}
                            </span>
                        </TrackingLink>
                    )}
                    {hasSubNav === true && (
                        <span className="hudl-nav__link--desktop">
                            <span className="hudl-nav__link-wrap">
                                {menuItem.label}
                                <Chevron />
                            </span>
                        </span>
                    )}
                    {hasSubNav === true && (
                        <ul className="hudl-sub-nav--desktop">
                            {menuItem.subMenuItems.map(subItem => (
                                <li key={subItem.label.toLowerCase().replace(/\s/g, '')} className="hudl-sub-nav__item--desktop">
                                    <TrackingLink component="sub_nav" to={subItem.linkUrl} className="hudl-sub-nav__link--desktop">{subItem.label}</TrackingLink>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            );
        }
        if (menuItem.linkType === 'button') {
            return <li key={`${menuItem.linkUrl}-${index.toString()}`} className="hudl-nav__button--desktop"><Button component="top_nav" variant="primary-button-dark" to={menuItem.linkUrl}>{menuItem.label}</Button></li>;
        }
        return null;
    });

    const preNav2 = <TrackingLink component="top_nav" to={headerSettings[pageLanguageName].headerPreNav2[0].path}>{headerSettings[pageLanguageName].headerPreNav2[0].label}</TrackingLink>;

    // Find the CTA buttons.
    const ctaButtons = mainNavigation.filter(menuItem => menuItem.linkType === 'button');

    return (
        <>
            <Helmet>
                <body className={hudlHamburgerMenuOpen ? 'scroll-lock' : ''} />
            </Helmet>

            <div className="hudl-navigation">

                <div className={hudlHamburgerMenuOpen ? 'hudl-menu--mobile hudl-menu--mobile--open' : 'hudl-menu--mobile'}>
                    <nav className="hudl-nav--mobile">
                        <Accordion className="hudl-nav__list--mobile" allowZeroExpanded>
                            {mobileNav}
                        </Accordion>
                    </nav>
                    <div className="hudl-bottom-nav--mobile">
                        <div className="hudl-container hudl-padding-x--lg">
                            <UsSiteModal />
                            {preNav2}
                            <LanguagePicker />
                        </div>
                    </div>
                </div>


                <div className="hudl-pre-nav">
                    <Container>
                        <div className="right-side">
                            <UsSiteModal />
                            {preNav2}
                            <LanguagePicker />
                        </div>
                    </Container>
                </div>

                <div className="hudl-menu--desktop">
                    <Container>
                        <TrackingLink component="top_nav" className="hudl-logo" to={`/${defaultPath}/`}>
                            <Logo />
                        </TrackingLink>
                        <div className="top_nav_CTA_wrapper--mobile">
                            { ctaButtons.map(button => <Button key={button.linkUrl + button.label} component="top_nav" variant="primary-button-dark" to={button.linkUrl}>{button.label}</Button>) }
                            <button type="button" className={hudlHamburgerMenuOpen ? 'hudl-menu-icon hudl-menu-icon--open' : 'hudl-menu-icon'} onClick={togglehudlHamburgerMenu} onKeyPress={hudlHamburgerMenuKeyDown}>
                                <span aria-hidden="true" className="line" />
                                <span aria-hidden="true" className="line" />
                                <span aria-hidden="true" className="line" />
                                <span aria-hidden="true" className="line" />
                            </button>
                        </div>
                        
                        <nav className="hudl-nav--desktop">
                            <ul className="hudl-nav__list--desktop">
                                {mainNav}
                            </ul>
                        </nav>
                    </Container>
                </div>
            </div>
        </>
    );
};

Navigation.defaultProps = {
    type: 'navigation'
};

export default Navigation;
